.rent-free-form .form-control {
    border-radius: 15px;
    padding: 9px 20px;
    font-size: 17px;
}
.rent-free-form .btn-submit {
    width: 100%;
    padding: 10px;
    font-size: 18px;
    margin-top: 0;
}
.rent-free-form .form-check {
    align-items: flex-start;
}
.form-date-input{
    position: relative;
}
#lease_begin::before { 
    content: attr(data-placeholder);
    width: 80%;
    color: #a598ab;
    position: absolute;
    background: #fff;
}
#lease_begin:focus::before,
#lease_begin.hasvalue::before{
    display: none;
}
#lease_expire::before { 
    content: attr(data-placeholder);
    width: 80%;
    color: #a598ab;
    position: absolute;
    background: #fff;
}
#lease_expire:focus::before,
#lease_expire.hasvalue::before{
    display: none;
}