.driver_dashboard{
    background-image: url('../../../assests/images/driver/driver_dashboard.jpeg');
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
}

#driver_update_btn{
    background: linear-gradient(rgb(6, 92, 202), rgb(15, 120, 213)) !important;
    border: 2px solid rgb(4, 135, 166) !important;
    color: #fff;
    border-radius: 20px;
    font-size: 17px;
    font-weight: 600;
}

.driver-btn{
    background: linear-gradient(rgb(6, 92, 202), rgb(15, 120, 213));
    border: 2px solid rgb(4, 135, 166);
    padding: 7px 10px;
    color: #fff;
    display: inline-block;
    text-decoration: none;
    border-radius: 20px;
    font-size: 17px;
    font-weight: 600;
    min-width: 180px;
}
#driver_dob{
    position: relative;
}
#driver_dob::before { 
    content: attr(data-placeholder);
    width: 80%;
    color: #a598ab;
    position: absolute;
    background: #fff;
}
#driver_dob:focus::before,
#driver_dob.hasvalue::before{
    display: none;
}
.image-upload-block {
    background: rgba(255, 255, 255, 0.71);
    text-align: center;
    padding: 15px;
    width: 300px;
}
.image-upload-block .image-upload {
    height: 100px;
    object-fit: contain;
    margin: auto;
    margin-bottom: 15px;
}
.image-upload-block .form-label {
    display: block;
    font-weight: 600;
}
.image-upload-block .form-file-input {
    width: 100%;
}