.form-control:focus,
.form-select:focus{
    box-shadow: none !important;
}

.top-banner {
    height: 45px;
    transition: all 2s ease-in-out;
}
.top-banner.open {
    height: 0;
    transition: all 2s ease-in-out;
}
.dashboard-navbar{
    padding-top: 30px;
}
.business-dashboard .main-inner{
    margin-top: 10px;
    border: 1px solid #ccc;
    border-radius: 50px;
    margin-bottom: 10px;
}
@media (max-width: 800px){
    .top-banner {
        height: 68px;
    }
}
@media (max-width: 767px){
    .dashboard-navbar .logo{
        margin-left: auto !important;
        margin-right: auto !important;
    }
    .header-heading-section.center{
        text-align: center;
        margin: auto;
    }
}
/* driver now form */
.form-driver-now label{
    font-weight: bold;
    color: #000;
}
.form-driver-now .form-control{
    background: #cae4fb !important;
    height: 40px;
    border-radius: 50px;
    border: 1px solid #3d94fe;
    font-size: 18px;
    color: #454545;
    padding-left: 20px;
}
.form-driver-now .form-control:focus {
    box-shadow: none;
}
.form-driver-now .form-control:disabled{
    background: rgb(206, 206, 206) !important;
}
.calculate_button {
    height: 45px;
    background: #062951;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-family: Asap;
    font-size: 16px;
    font-weight: 500;
    border: 2px solid #fff;
    outline: 2px solid #3ea5b8 !important;
    position: relative;
    box-shadow: none;
    padding: 10px 30px;
    z-index: 2;
    transition: all .3s;
    margin-top: 28px;
    width: 100%;
}
.calculate_button:hover{
    background: #000;
}
.calculate_button:focus {
    outline: 2px solid #3ea5b8 !important;
}
#delivery_address {
    padding-left: 30px;
}
.marker-location {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(20%);
}
.switch-icon {
    border: 1px solid #03244f;
}
.quickorder {
    height: 50px;
    width: 100%;
    background: green;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    text-transform: uppercase;
    font-family: Asap;
    font-size: 20px;
    font-weight: 600;
    border: 2px solid #fff;
    outline: 2px solid #3ea5b8 !important;
    position: relative;
    box-shadow: none;
}
.quickorder:hover{
    background: #000;
}
.quickorder:focus {
    outline: 2px solid #3ea5b8 !important;
}
.dashboard-btns .btn-dash{
    padding: 8px 2px;
    line-height: 1;
    width: 100%;
    background: #052755;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-family: Asap;
    font-size: 16px;
    font-weight: 500;
    border: 2px solid #fff;
    outline: 2px solid #3ea5b8 !important;
    position: relative;
    box-shadow: none;
    margin-right: 15px;
    text-decoration: none;
}
.dashboard-btns .btn-dash:focus {
    outline: 2px solid #3ea5b8 !important;
}
.dashboard-btns .btn-dash:hover{
    background: #000 !important;
}
.add_more_orders {
    background: #008000 !important;
}
.btn-text-ads{
    background: #a349a4 !important;
}
@media (max-width: 1399px) {
    .form-driver-now label,
    .dashboard-btns .btn-dash {
        font-size: 14px;
    }
    .switch-label label{
        font-size:14px;
    }
}

/* range slider */
.rangeSlider.rc-slider {
    height: 10px;
    padding: 0;
    border-radius: 0;
    border: 3px solid #001d49;
}
.rangeSlider.rc-slider .rc-slider-dot{
    display: none;
}
.rangeSlider.rc-slider .rc-slider-track{
    background-color: #3ea5f0;
}
.rangeSlider.rc-slider .rc-slider-handle {
    background: #3ca3ee;
    border: 4px solid #fff;
    border-radius: 50%;
    box-shadow: 0 0 0 2px #3ca3ee;
    opacity: 1;
}
.rangeSlider.rc-slider .rc-slider-mark{
    top: 14px;
}
.rangeSlider.rc-slider .rc-slider-mark .rc-slider-mark-text {
    color: #000;
    font-weight: 600;
}
/* Footer btns */
.footer-btn-links .nav-item a, .footer-btn-links .nav-item a.active {
    background: #062951 !important;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff !important;
    font-family: 'Asap';
    font-size: 16px;
    font-weight: 500;
    border: 2px solid #fff;
    outline: 2px solid #3ea5b8 !important;
    position: relative;
    box-shadow: none;
    margin-left: 15px;
    padding: 10px 30px !important;
    z-index: 2;
    transition: all .3s;
    margin-top: 20px;
}
.footer-btn-links .nav-item a:hover{
    background: #000 !important;
}
.footer-btn-links .nav-item a:focus {
    outline: 2px solid #3ea5b8 !important;
}
.footer-btn-links .nav-item a.green-btn{
    background-color: #008000 !important;
}
.footer-btn-links .nav-item a.green-btn:hover{
    background-color: #000 !important;
}
.green-btn{
    outline-color: #008000 !important;
}
.tab-content {
    background-color: rgb(250, 250, 250);
}
.popup-dashboard-modal .modal-dialog{
    max-width: 1200px;
}
.popup-dashboard-modal .modal-dialog .modal-body {
    position: relative;
    max-height: 65vh;
    overflow-y: auto;
}
.dashboard-backdrop {
    opacity: 0.8 !important;
}

/* Profile Paymnet form css */
.business_profile_form .select-flex .form-select{
    width: max-content !important;
}
#cc_cvv{
    width: 80px;
}
@media (max-width: 639px){
    .business_profile_form .select-flex .form-select{
        width: 100% !important;
    }
    #cc_cvv{
        width: 100%;
    }
}


/* Stat section */
.stat-section .btn-section button {
    border: 1px solid #032852;
    color: #032852;
    border-radius: 30px;
    padding: 5px 18px;
    margin-right: 8px;
    transition: all 0.3s;
}
.stat-section .btn-section button:hover {
    background-color: #032852;
    color: #fff;
}
.stat-section .btn-section button.btn-red {
    background: #ed1c24;
    border-color: #ed1c24;
    color: #fff;
}
.stat-section .btn-section button.btn-green {
    background: #008000;
    border-color: #008000;
    color: #fff;
}
.stat-section .btn-section button.btn-green:hover,
.stat-section .btn-section button.btn-red:hover{
    color: #000;
}
.internal-orders-table {
    border-collapse: separate;
    border-spacing: 0 5px;
}
.internal-orders-table tbody {
    margin-top: 10px;
    border: none;
}
.internal-orders-table thead th:first-child {
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
}
.internal-orders-table thead th {
    background-color: #062752;
    color: #fff;
    font-family: Asap;
    font-size: 14px;
    padding: 13px;
    border: 0.5px solid #fff;
    text-align: center;
    vertical-align: middle;
}
.internal-orders-table thead th:last-child {
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
}

.internal-orders-table tbody tr td {
    border: 1px solid #a2d3fc;
    vertical-align: middle;
    background: transparent;
    padding: 5px;
    text-transform: capitalize;
}
.internal-orders-table tbody tr td:first-child {
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
    padding-left: 30px;
}
.internal-orders-table tbody tr td:last-child {
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
}
.internal-orders-table tbody tr td img{
    object-fit: contain;
}
.internal-orders-table tbody tr {
    background: #c9e5fd;
}
.internal-orders-table tbody tr td.bgpurple {
    background: #a349a4 !important;
    color: #fff !important;
    border-color: #a349a4;
}
.internal-orders-table tbody tr td.driver_map_block span{
    display: block;
}
.internal-orders-table tbody tr td.driver_map_block span.driver_name {
    font-size: 24px;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 20px;
}
.internal-orders-table tbody tr td.driver_map_block span.driver_map span {
    display: inline;
    cursor: pointer;
}
.orders_messages_div {
    background: lightgray;
    border-radius: 5px;
    padding: 10px;
    overflow: auto;
}
.orders-button-messages td {
    border: none !important;
    padding: 0 !important;
}
.system-moniter{
    background: #00a8c0 !important;
}
.orders-button-rows{
    background: #fff !important;
    
}
.orders-button-rows td{
    border: none !important;
    padding: 0 !important;
}
.orders_sms_div .orders_sms{
    font-size: 14px !important;
    line-height: 19px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff !important;
    background: #062752 !important;
    border-radius: 11px;
    height: 64px;
    padding: 4px;
    font-size: 11px !important;
    position: relative;
    width: 100%;
    border: 2px solid #fff !important;
    outline: 2px solid #3ea5b8 !important;
}
.orders_sms_div .orders_sms.active{
    background: #ed1c24 !important;
}
.orders_sms_div .orders_sms.green_btn{
    background: #008000 !important;
}
.orders_sms_div{
    border: none !important;
    background: #fff !important;
    color: #fff;
    border-radius: 32px;
    padding: 5px !important;
    vertical-align: middle;
}
.orders_sms_div .orders_sms:focus {
    outline: 2px solid #3ea5b8 !important;
}
@media (max-width: 767px){
    .internal-orders-table {
        width: 400% !important;
    }
}
/* Trip Content */
.custom_trips_table_outer {
    background: #e1f2fa;
    float: left;
    width: 100%;
    border-radius: 25px;
    padding: 15px;
    margin-bottom: 16px;
    border: 2px solid #e7e9eb;
}
.custom_trips_table_outer .order_today{
    background: #062752;
    color: #fff !important;
    padding: 5px;
    border-radius: 5px;
    font-size: 15px;
    font-weight: normal;
    text-align: center;
    width: 250px;
    border-radius: 50px;
    position: relative;
    border: 2px solid #fff;
    outline: 2px solid #3ea5b8 !important;
}
.custom_trips_table_outer .order_today:focus {
    outline: 2px solid #3ea5b8 !important;
}
.custom_trips_table_outer .order_today.green, .custom_trips_table_outer .order_today.active{
    background: #008000;
}
.custom_trips_table_outer .order_today.green{
    width: 160px;
}
.custom_trips_table_outer .order_today:hover{
    background: #000;
}
.trip-date{
    width: 135px;
    border: 1px solid #3ea5b8;
    border-radius: 50px;
    padding: 5px;
}
.saving_table_div {
    background: #fff;
    color: #000;
    border-radius: 5px;
    text-align: center;
    padding: 5px;
    border: 1px solid #000;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
}
@media (max-width: 380px){
    .custom_trips_table_outer .order_today{
        width: 100% !important;
    }
}
/* Delay Driver */
.delay_choose_btn {
    min-width: 115px;
    background: #b7b2ae;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-family: Asap;
    font-size: 16px;
    font-weight: 500;
    border: 2px solid #fff;
    outline: 2px solid #3ea5b8 !important;
    position: relative;
    box-shadow: none;
    padding: 6px 30px;
    z-index: 2;
    line-height: 1;
    transition: all .3s;
}
.delay_choose_btn:focus {
    outline: 2px solid #3ea5b8 !important;
}
.delay_choose_btn:hover{
    color: #000 !important;
}
.delay_choose_btn:first-child.active {
    background: #052755;
}
.delay_choose_btn:first-child.active:hover {
    color: gray !important;
}
.delay_choose_btn.active {
    background: #ed1c24;
}
.delay_choose_btn.btn-red {
    background: #ed1c24;
}
.delay_choose_btn.btn-green {
    background: #008000;
}
.delay_choose_btn.btn-green:hover,
.delay_choose_btn.btn-red:hover{
    color: #000 !important;
}
.delay_choose_btn.btn_order_sound {
    padding: 8px 30px;
}
.delay_choose_btn.btn_order_sound.btn-green {
    border: 2px solid #008000;
    outline: 2px solid #008000 !important;
}
.delay_choose_btn.btn_order_sound.btn-red {
    border: 2px solid #ed1c24;
    outline: 2px solid #ed1c24 !important;
}
/* Credit card form */
.card-form-btns{
    background: #052755 !important;
    border-radius: 15px !important;
    width: 151px;
    color: #fff;
    border: none !important;
}

/* Scan drivers */
.scan-drivers-img{
    height: 80px;
    width: 80px;
    border: 2px solid #2db7e6;
    border-radius: 100%;
    overflow: hidden;
    object-fit: cover;
}
.driver_div h5{
    width: 80px;
    font-family: Asap;
    font-size: 18px;
    font-weight: 600;
    margin-top: 10px;
    text-align: center;
}
.driver_div{
    text-align: center;
    cursor: pointer;
    transition: all .3s;
}
.driver_div:hover {
    transform: scale(1.2);
}

/* trips requested */
.trips_data th:first-child {
    border-radius: 25px 0 0 0;
}
.trips_data th {
    background: #e6e6e6 !important;
    border-bottom: 2px solid #e7e9eb;
}
.trips_data th:last-child {
    border-radius: 0 25px 0 0;
}
.trips_data th {
    text-transform: uppercase;
    font-size: 14px !important;
    text-align: center;
    line-height: 24px;
    vertical-align: middle;
}
.trips_data td{
    background-color: rgb(244, 244, 244) !important;
    text-align: center;
    border-color: #fff !important;
    border-right: 1px solid #CCCCCC;
    border-top: 1px solid #CCCCCC;
    vertical-align: middle;
}
.trips_data .total_rows td {
    background-color: #cecece !important;
    font-weight: 600;
}
.trips_data .bg-yellow td{
    background-color: #fffd94 !important;
}
.trips_data .bg-gray{
    background-color: #cecece !important;
}
.trips_data .font-bold td{
    font-weight: 600;
}
.trips_data .orders-button-messages td {
    background-color: #3fa9f5 !important;
}
.trips_data .orders-button-messages .orders_messages_div {
    background: #c9e3ff;
    border-radius: 5px;
    padding: 10px;
    overflow: auto;
    color: #000;
    text-align: left;
}
.delivery_far{
    background: #fffd94;
    color: #000;
    width: 100%;
    margin-top: 15px;
    border-radius: 5px;
    text-align: center;
    padding: 5px;
    font-size: 17px;
    font-weight: 400;
    border: 1px solid;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.delivery_far span {
    width: 100%;
}
.delivery_far button {
    background-color: #008000;
    color: #fff;
    padding: 5px 15px;
    border-radius: 10px;
}
.delivery_far button:hover {
    background-color: #000;
}
.form_trips_password .form-control {
    font-size: 18px;
    padding: 10px 20px;
}
.form_trips_password .submit_trips_password {
    background: #062951;
    font-size: 18px;
    border-radius: 30px;
    padding: 10px 35px;
    border: 1px solid #062951;
    line-height: 1.3;
}
.form_trips_password .submit_trips_password:hover {
    background: #000;
    border: 1px solid #000;
}

/* Special Delay popup */
.special_delay_popup {
    background-color: #55bceb;
    position: fixed;
    bottom: 2px;
    z-index: 1111;
    border: 10px solid #12a2e4;
    padding: 20px 10px;
    border-radius: 10px;
    color: #fff;
    text-align: center;
    font-size: 18px;
}